<script>
  import FormBody from "./FormBody.svelte";
  import Progress from "./Progress.svelte";
  import { createEventDispatcher } from "svelte";
  import { generateJson, fillFromJson, isDataModified } from "./store.js";
  import ChoiceModal from "./ChoiceModal.svelte";
  import { writable } from "svelte/store";

  //PopUpTools
  export let show = false;
  let showChoiceModal = writable(false);

  const dispatcher = createEventDispatcher();

  function handleCancel() {
    console.log("isDataModified", $isDataModified);
    if ($isDataModified) {
      showChoiceModal.set(true);
    }else{
      dispatcher("cancel")
    }
  }

  function handleChoiceModalCancel() {
    showChoiceModal.set(false);
    dispatcher("cancel")
  }

  function handlePopUpConfirm(event) {
    let returnedData = event.detail.detail.alert;
    console.log("Data received from form:", returnedData);
    dispatcher("confirm", { detail: { alert: returnedData } });
  }
</script>

<div>

  <ChoiceModal
    show={$showChoiceModal}
    title="Cancelar edición"
    message="¿Está seguro que desea cancelar la operación? Los cambios no se guardarán."
    on:confirm={handleChoiceModalCancel}
    on:cancel={() => showChoiceModal.set(false)}
  />
</div>

{#if show}
  <div
    class="d-flex justify-content-center align-items-center popup"
    style="height: 100%; background-color: rgba(0, 0, 0, 0.5);"
  >
    <div class="card-container shadow p-3 mb-5 mt- bg-body row">
      <div class="d-flex justify-content-end">
        <button
          class="btn btn-outline-secondary btn-sm"
          style="max-height: 30px;"
          on:click={handleCancel}
        >
          <i class="bi bi-x m-auto"></i></button
        >
      </div>
      <div class="col-3">
        <Progress />
      </div>
      <div class="col-9" style="max-height: 80vh; overflow:auto;">
        <FormBody on:confirm={handlePopUpConfirm} />
      </div>
    </div>
  </div>
{/if}

<style>
  .popup {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .elevated {
    box-shadow:
      0 0px 2.2px rgba(0, 0, 0, 0.034),
      0 0px 5.3px rgba(0, 0, 0, 0.048),
      0 0px 10px rgba(0, 0, 0, 0.06),
      0 0px 17.9px rgba(0, 0, 0, 0.072),
      0 0px 33.4px rgba(0, 0, 0, 0.086),
      0 0px 80px rgba(0, 0, 0, 0.12);
  }

  .card-container {
    border-radius: 20px;
    padding: 24px;

    width: 80%;
    overflow: auto;
  }

  .toggle-button {
    width: 5rem;
  }

  label {
    font-weight: bold;
  }
</style>
