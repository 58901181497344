<script>
  import {
    devicesTypes,
    alertTypes,
    operators,
    geoOperators,
    ingnitionValues,
    eventCodes,
    hdopValues,
    formFiveData,
    isFormCompleted,
    cronCounter,
    geoValues,
    isDataModified,
    manualValues,
    geoDestValues
  } from "./store.js";
  import { onMount } from "svelte";
  import { toast } from "svelte-french-toast";

  let currentTabIndex = 0;
  let localData;
  let initialData;

  function checkForModifications() {
    if (!$isDataModified) {
      isDataModified.set(JSON.stringify(localData) !== JSON.stringify(initialData));
    }
  }

  formFiveData.subscribe((value) => {
    localData = value;
    validateFormCompletion();
  });

  devicesTypes.subscribe(() => {
    updateDeviceValues();
  });

  function updateformFiveData() {
    formFiveData.set(localData);
    validateFormCompletion();
    checkForModifications();
  }

  function updateDeviceValues() {
    localData.conditionGroups.forEach((group) => {
      group.rows.forEach((row) => {
        row.deviceValues = $devicesTypes.map((device) => {
          const existingValue = row.deviceValues.find(
            (d) => d.value === device.value
          );
          return {
            ...device,
            inputValue: existingValue
              ? existingValue.inputValue
              : getInitialInputValue(row.selectedEventIndex, device.value),
          };
        });
      });
    });
    updateformFiveData();
  }

  function getOperators(selectedEvent) {
    return selectedEvent.name === "geo_type" ? geoOperators : operators;
  }

  function handleDeviceChange(groupIndex, rowIndex, deviceValue, event) {
    localData.conditionGroups[groupIndex].rows[rowIndex].deviceValues.find(
      (device) => device.value === deviceValue
    ).inputValue = event.target.value;
    updateformFiveData();
  }

  function addCondition(groupIndex) {
    if (validateForm(groupIndex)) {
      let conditionsToAdd = localData.conditionGroups[groupIndex].rows.map(
        (row) => {
          return {
            event: alertTypes[row.selectedEventIndex].name,
            operator: getOperators(alertTypes[row.selectedEventIndex])[
              row.operatorIndex
            ].name,
            value: row.deviceValues.reduce((acc, device) => {
              if (device.checked || device.name === "default") {
                acc[device.value] = device.inputValue;
              }
              return acc;
            }, {}),
          };
        }
      );
      localData.conditionGroups[groupIndex].conditions = [
        ...localData.conditionGroups[groupIndex].conditions,
        ...conditionsToAdd,
      ];
      updateformFiveData();
      resetForm(groupIndex);
    } else {
      toast.error(
        "Por favor completa todos los campos antes de agregar la condición."
      );
    }
  }

  function deleteCondition(groupIndex, conditionIndex) {
    localData.conditionGroups[groupIndex].conditions =
      localData.conditionGroups[groupIndex].conditions.filter(
        (_, i) => i !== conditionIndex
      );
    updateformFiveData();
  }

  function addConditionGroup() {
    localData.conditionGroups = [
      ...localData.conditionGroups,
      {
        rows: [
          {
            selectedEventIndex: 0,
            operatorIndex: 0,
            selectedValue: "",
            selectedValueIndexes: $devicesTypes.map(() => 0),
            deviceValues: $devicesTypes.map((device) => ({
              ...device,
              inputValue: getInitialInputValue(0, device.value),
            })),
          },
        ],
        conditions: [],
      },
    ];
    currentTabIndex = localData.conditionGroups.length - 1;
    updateformFiveData();
  }

  function deleteConditionGroup(index) {
    localData.conditionGroups = localData.conditionGroups.filter(
      (_, i) => i !== index
    );
    const newIndex = Math.max(
      0,
      Math.min(currentTabIndex, localData.conditionGroups.length - 1)
    );
    currentTabIndex = newIndex;
    updateformFiveData();
  }

  function addConditionRow(groupIndex) {
    localData.conditionGroups[groupIndex].rows = [
      ...localData.conditionGroups[groupIndex].rows,
      {
        selectedEventIndex: 0,
        operatorIndex: 0,
        selectedValue: "",
        selectedValueIndexes: $devicesTypes.map(() => 0),
        deviceValues: $devicesTypes.map((device) => ({
          ...device,
          inputValue: getInitialInputValue(0, device.value),
        })),
      },
    ];
    updateformFiveData();
  }

  function deleteConditionRow(groupIndex, rowIndex) {
    localData.conditionGroups[groupIndex].rows = localData.conditionGroups[
      groupIndex
    ].rows.filter((_, i) => i !== rowIndex);
    updateformFiveData();
  }

  function resetForm(groupIndex) {
    localData.conditionGroups[groupIndex].rows = [
      {
        selectedEventIndex: 0,
        operatorIndex: 0,
        selectedValue: "",
        selectedValueIndexes: $devicesTypes.map(() => 0),
        deviceValues: $devicesTypes.map((device) => ({
          ...device,
          inputValue: getInitialInputValue(0, device.value),
        })),
      },
    ];
    updateformFiveData();
  }

  function resetRowValues(groupIndex, rowIndex) {
    const selectedEventIndex =
      localData.conditionGroups[groupIndex].rows[rowIndex].selectedEventIndex;
    localData.conditionGroups[groupIndex].rows[rowIndex] = {
      selectedEventIndex,
      operatorIndex: 0,
      selectedValue: "",
      selectedValueIndexes: $devicesTypes.map(() => 0),
      deviceValues: $devicesTypes.map((device) => ({
        ...device,
        inputValue: getInitialInputValue(selectedEventIndex, device.value),
      })),
    };
    updateformFiveData();
  }

  function validateForm(groupIndex) {
    return localData.conditionGroups[groupIndex].rows.every((row) => {
      return row.deviceValues.every((device) =>
        device.checked || device.name === "default"
          ? device.inputValue !== ""
          : true
      );
    });
  }

  function isConditionRowComplete(row) {
    return row.deviceValues.every((device) =>
      device.checked || device.name === "default"
        ? device.inputValue !== ""
        : true
    );
  }

  function validateFormCompletion() {
    let isCompleted = true;
    let missingFields = [];

    let templateObject = {
      count: localData.alertTrend,
      counter: [],
    };

    if (localData.alertTrend <= 0) {
      isCompleted = false;
      missingFields.push("Tendencia (alertTrend)");
    }

    localData.conditionGroups.forEach((group, groupIndex) => {
      let groupObject = {};
      let groupCompleted = true;

      group.rows.forEach((row, rowIndex) => {
        if (!isConditionRowComplete(row)) {
          groupCompleted = false;
          missingFields.push(
            `Grupo de Condiciones ${groupIndex + 1}, Condición ${rowIndex + 1}`
          );
        }

        let selectedAlertType = alertTypes[row.selectedEventIndex].name;
        let selectedOperator = getOperators(alertTypes[row.selectedEventIndex])[
          row.operatorIndex
        ].value;
        let selectedValue = {};

        row.deviceValues.forEach((device, deviceIndex) => {
          if (device.checked || device.name === "default") {
            selectedValue[device.value] = device.inputValue;
          }
        });

        if (
          selectedAlertType &&
          selectedOperator &&
          Object.keys(selectedValue).length > 0
        ) {
          groupObject[selectedAlertType] = {
            type: selectedOperator,
            val: selectedValue,
          };
        }
      });

      if (groupCompleted && Object.keys(groupObject).length > 0) {
        templateObject.counter.push(groupObject);
      }
    });

    cronCounter.set(templateObject.counter);
    isFormCompleted.set(isCompleted);
  }

  function isInvalid(row) {
    return row.deviceValues.some((device) =>
      device.checked || device.name === "default"
        ? device.inputValue === ""
        : false
    );
  }

  function handleTabClick(index) {
    currentTabIndex = index;
    updateformFiveData();
  }

  function getConditionDescription(row) {
    const selectedAlertType = alertTypes[row.selectedEventIndex];
    const operator = getOperators(selectedAlertType)[row.operatorIndex];

    const filteredDeviceValues = row.deviceValues.filter(device => device.checked);

    // Agrupar los valores por dispositivo
    const deviceGroups = filteredDeviceValues.reduce((groups, device) => {
      const value = (() => {
        if (selectedAlertType.name === "event_code") {
          const event = eventCodes.find((e) => e.code === device.inputValue);
          return event ? event.event : device.inputValue;
        } else if (selectedAlertType.name === "ingnition") {
          const ignValue = ingnitionValues.find(
            (i) => i.value === parseInt(device.inputValue)
          );
          return ignValue ? ignValue.name : device.inputValue;
        } else if (selectedAlertType.name === "geo_type") {
          const geoValue = geoValues.find((g) => g.value === device.inputValue);
          return geoValue ? geoValue.name : device.inputValue;
        } else if (selectedAlertType.name === "manual") {
          const manualValue = manualValues.find(
            (m) => m.value === parseInt(device.inputValue)
          );
          return manualValue ? manualValue.name : device.inputValue;
        } else if (selectedAlertType.name === "geo_dest") {
          const geoDestValue = geoDestValues.find(
            (g) => g.value === device.inputValue
          );
          return geoDestValue ? geoDestValue.name : device.inputValue;
        }
        
        else {
          return device.inputValue;
        }
      })();

      if (!groups[value]) {
        groups[value] = [];
      }
      groups[value].push(device.name);
      return groups;
    }, {});

    // Formatear la descripción agrupada
    let valueDescriptions = [];
    let defaultDescription = "";

    Object.entries(deviceGroups).forEach(([value, devices]) => {
      const devicesWithoutDefault = devices.filter((d) => d !== "Por defecto");
      if (devices.includes("Por defecto")) {
        defaultDescription = `${value} para todos los demás`;
      }
      if (devicesWithoutDefault.length > 0) {
        valueDescriptions.push(
          `${value} para dispositivos ${devicesWithoutDefault.join(", ")}`
        );
      }
    });

    if (defaultDescription) {
      valueDescriptions.push(defaultDescription);
    }

    const description = selectedAlertType.description;

    return `Cuando ${description} ${operator.name.toLowerCase()} a ${valueDescriptions.join("; ")}${selectedAlertType.scale ? ` (${selectedAlertType.scale})` : ""}`;
  }

  function getInitialInputValue(selectedEventIndex, deviceType) {
    const selectedEvent = alertTypes[selectedEventIndex].name;
    if (selectedEvent === "ingnition") {
      return ingnitionValues[0].value;
    } else if (selectedEvent === "event_code") {
      return eventCodes[0].code;
    } else if (selectedEvent === "hdop") {
      return hdopValues[0].value;
    } else if (selectedEvent === "geo_type") {
      return geoValues[0].value;
    } else if (selectedEvent === "manual") {
      return manualValues[0].value;
    } else if (selectedEvent === "geo_dest") {
      return geoDestValues[0].value;
    } else {
      return "";
    }
  }

  onMount(() => {
    updateDeviceValues();
  });
</script>

<h2>Alertas CRON</h2>

<hr />

<h3>Condiciones</h3>
<div id="alertMessageHelp" class="form-text">
  Para que una alerta sea disparada, todas las condiciones de un grupo deben
  cumplirse el numero de veces definido en "tendencia", en el momento en que se
  realiza la consulta.
</div>
<div id="alertMessageHelp" class="form-text">
  De tener más de un grupo, se disparará la alerta si se cumplen todas las
  condiciones de al menos un grupo, en el momento en que se realiza la consulta.
</div>

<hr />

<!-- input de tendencia -->
<div class="mb-3">
  <label for="alertTrend" class="form-label">Tendencia</label>
  <input
    type="number"
    class="form-control"
    id="alertTrend"
    aria-describedby="alertTrendHelp"
    bind:value={localData.alertTrend}
    on:input={() => updateformFiveData()}
  />
  <div id="alertTrendHelp" class="form-text">
    Escribe el número de veces que se debe cumplir la condición o condiciones
    para disparar la alerta.
  </div>
</div>

<hr />

<!-- Tabs de Bootstrap para cada grupo de condiciones -->
<ul class="nav nav-tabs" id="myTab" role="tablist">
  {#if localData.conditionGroups.length > 0}
    {#each localData.conditionGroups as group, groupIndex}
      <li class="nav-item" role="presentation">
        <a
          class="nav-link {groupIndex === currentTabIndex ? 'active' : ''}"
          id="group-tab-{groupIndex}"
          data-bs-toggle="tab"
          href="#group-{groupIndex}"
          role="tab"
          aria-controls="group-{groupIndex}"
          aria-selected={groupIndex === currentTabIndex ? "true" : "false"}
          on:click={() => handleTabClick(groupIndex)}>Grupo {groupIndex + 1}</a
        >
      </li>
    {/each}
    <li class="nav-item">
      <button class="btn btn-success" on:click={addConditionGroup}>+</button>
    </li>
  {:else}
    <div class="d-flex justify-content-center" style="width: 100%;">
      <button class="btn btn-success mb-3" on:click={addConditionGroup}
        >Agregar Grupo de Condiciones</button
      >
    </div>
  {/if}
</ul>

<div class="tab-content" id="myTabContent">
  {#each localData.conditionGroups as group, groupIndex}
    <div
      class="tab-pane fade {groupIndex === currentTabIndex
        ? 'show active'
        : ''}"
      id="group-{groupIndex}"
      role="tabpanel"
      aria-labelledby="group-tab-{groupIndex}"
    >
      <div class="condition-group">
        <div class="d-flex mt-3 mb-3">
          <h4>Grupo de Condiciones {groupIndex + 1}</h4>
          <button
            class="btn btn-sm btn-danger ms-3"
            on:click={() => deleteConditionGroup(groupIndex)}
            ><i class="bi bi-trash-fill"></i></button
          >
        </div>

        <form on:submit|preventDefault={() => addCondition(groupIndex)}>
          <div class="accordion" id="accordion-{groupIndex}">
            {#each group.rows as row, rowIndex}
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  id="heading-{groupIndex}-{rowIndex}"
                >
                  <button
                    class="accordion-button {rowIndex === 0 ? '' : 'collapsed'}"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse-{groupIndex}-{rowIndex}"
                    aria-expanded={rowIndex === 0 ? "true" : "false"}
                    aria-controls="collapse-{groupIndex}-{rowIndex}"
                    on:click={() => validateFormCompletion()}
                  >
                    <h5>Condición {rowIndex + 1}</h5>
                  </button>
                </h2>
                <div
                  id="collapse-{groupIndex}-{rowIndex}"
                  class="accordion-collapse collapse {rowIndex === 0
                    ? 'show'
                    : ''}"
                  aria-labelledby="heading-{groupIndex}-{rowIndex}"
                  data-bs-parent="#accordion-{groupIndex}"
                >
                  <div class="accordion-body">
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        class="btn btn-danger btn-sm me-2"
                        disabled={group.rows.length <= 1}
                        on:click={() =>
                          deleteConditionRow(groupIndex, rowIndex)}
                      >
                        <i class="bi bi-trash-fill"></i>
                      </button>
                    </div>

                    <div class="row">
                      <!--select para tipo de alerta-->
                      <div class="mb-3 col-6">
                        <label
                          for="alertTypeSelect-{groupIndex}-{rowIndex}"
                          class="form-label">Tipo de Alerta</label
                        >
                        <select
                          class="form-select"
                          id="alertTypeSelect-{groupIndex}-{rowIndex}"
                          bind:value={row.selectedEventIndex}
                          on:change={() => resetRowValues(groupIndex, rowIndex)}
                        >
                          {#each alertTypes as alertType, index}
                            <option value={index} selected={index === 0}
                              >{alertType.nombre_esp}</option
                            >
                          {/each}
                        </select>
                        <div
                          id="alertTypeHelp-{groupIndex}-{rowIndex}"
                          class="form-text"
                        >
                          Selecciona el tipo de alerta que se disparará
                        </div>
                      </div>
                      {#if alertTypes[row.selectedEventIndex] && getOperators(alertTypes[row.selectedEventIndex])[row.operatorIndex]}
                        <div class="mb-3 col-6">
                          <label
                            for="comparator-{groupIndex}-{rowIndex}"
                            class="form-label">Comparador</label
                          >
                          <select
                            class="form-select"
                            id="comparator-{groupIndex}-{rowIndex}"
                            bind:value={row.operatorIndex}
                            disabled={alertTypes[row.selectedEventIndex]
                              .name === "ingnition" ||
                              alertTypes[row.selectedEventIndex].name ===
                                "event_code" ||
                              alertTypes[row.selectedEventIndex].name ===
                                "hdop" ||
                              alertTypes[row.selectedEventIndex].name ===
                                "geo_type" ||
                              alertTypes[row.selectedEventIndex].name ===
                                "manual" ||
                              alertTypes[row.selectedEventIndex].name ===
                                "geo_dest"

                                }
                            on:change={() => validateFormCompletion()}
                          >
                            {#each getOperators(alertTypes[row.selectedEventIndex]) as operator, index}
                              <option value={index} selected={index === 0}
                                >{operator.name}</option
                              >
                            {/each}
                          </select>
                          <div
                            id="comparatorHelp-{groupIndex}-{rowIndex}"
                            class="form-text"
                          >
                            Selecciona el comparador para la condición
                          </div>
                        </div>

                        <div class="col-12">
                          <hr style="margin-left:290px;" />
                        </div>
                        <div class="col-12" style="margin-top: -32px;">
                          <h5>Valores por tipo de dispositivo</h5>
                        </div>
                        {#each row.deviceValues.filter((device) => device.checked || device.name === "default") as device, deviceIndex}
                          {#if alertTypes[row.selectedEventIndex].name === "ingnition" || alertTypes[row.selectedEventIndex].name === "event_code" || alertTypes[row.selectedEventIndex].name === "hdop" || alertTypes[row.selectedEventIndex].name === "geo_type" || alertTypes[row.selectedEventIndex].name === "manual" || alertTypes[row.selectedEventIndex].name === "geo_dest"}
                            <div class="mb-3 col-sm">
                              <label
                                for="deviceValue-{groupIndex}-{rowIndex}-{device.value}"
                                class="form-label"
                                >{device.name}
                                {alertTypes[row.selectedEventIndex].scale
                                  ? `(${alertTypes[row.selectedEventIndex].scale})`
                                  : ""}</label
                              >
                              <div class="input-group flex-nowrap">
                                <span
                                  class="input-group-text"
                                  id="addon-wrapping"
                                  ><i class="bi bi-cpu"></i></span
                                >
                                <select
                                  class="form-select"
                                  id="deviceValue-{groupIndex}-{rowIndex}-{device.value}"
                                  aria-describedby="alertValueHelp"
                                  bind:value={row.selectedValueIndexes[
                                    deviceIndex
                                  ]}
                                  on:change={(e) => {
                                    if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "ingnition"
                                    ) {
                                      device.inputValue =
                                        ingnitionValues[e.target.value].value;
                                    } else if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "event_code"
                                    ) {
                                      device.inputValue =
                                        eventCodes[e.target.value].code;
                                    } else if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "hdop"
                                    ) {
                                      device.inputValue =
                                        hdopValues[e.target.value].value;
                                    } else if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "geo_type"
                                    ) {
                                      device.inputValue =
                                        geoValues[e.target.value].value;
                                    } else if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "manual"
                                    ) {
                                      device.inputValue =
                                        manualValues[e.target.value].value;
                                    } else if (
                                      alertTypes[row.selectedEventIndex]
                                        .name === "geo_dest"
                                    ) {
                                      device.inputValue =
                                        geoDestValues[e.target.value].value;
                                    }
                                    validateFormCompletion();
                                  }}
                                >
                                  {#if alertTypes[row.selectedEventIndex].name === "ingnition"}
                                    {#each ingnitionValues as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.name}</option
                                      >
                                    {/each}
                                  {:else if alertTypes[row.selectedEventIndex].name === "event_code"}
                                    {#each eventCodes as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.event}</option
                                      >
                                    {/each}
                                  {:else if alertTypes[row.selectedEventIndex].name === "hdop"}
                                    {#each hdopValues as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.name}</option
                                      >
                                    {/each}
                                  {:else if alertTypes[row.selectedEventIndex].name === "geo_type"}
                                    {#each geoValues as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.name}</option
                                      >
                                    {/each}
                                  {:else if alertTypes[row.selectedEventIndex].name === "manual"}
                                    {#each manualValues as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.name}</option
                                      >
                                    {/each}
                                  {:else if alertTypes[row.selectedEventIndex].name === "geo_dest"}
                                    {#each geoDestValues as value, index}
                                      <option
                                        value={index}
                                        selected={index === 0}
                                        >{value.name}</option
                                      >
                                    {/each}
                                  {/if}
                                </select>
                              </div>
                              <div
                                id="alertValueHelp-{groupIndex}-{rowIndex}"
                                class="form-text {isInvalid(row) &&
                                !device.inputValue
                                  ? 'text-danger'
                                  : ''}"
                              >
                                Selecciona el valor que se comparará con el
                                evento
                              </div>
                            </div>
                          {:else}
                            <div class="mb-3 col-sm">
                              <label
                                for="deviceValue-{groupIndex}-{rowIndex}-{device.value}"
                                class="form-label"
                                >{device.name}
                                {alertTypes[row.selectedEventIndex].scale
                                  ? `(${alertTypes[row.selectedEventIndex].scale})`
                                  : ""}</label
                              >
                              <div class="input-group flex-nowrap">
                                <span
                                  class="input-group-text"
                                  id="addon-wrapping"
                                  ><i class="bi bi-cpu"></i></span
                                >
                                <input
                                  type="text"
                                  class="form-control"
                                  id="deviceValue-{groupIndex}-{rowIndex}-{device.value}"
                                  bind:value={device.inputValue}
                                  on:input={(e) =>
                                    handleDeviceChange(
                                      groupIndex,
                                      rowIndex,
                                      device.value,
                                      e
                                    )}
                                  on:input={() => validateFormCompletion()}
                                />
                              </div>
                              <div
                                id="deviceValueHelp-{groupIndex}-{rowIndex}-{device.value}"
                                class="form-text {isInvalid(row) &&
                                !device.inputValue
                                  ? 'text-danger'
                                  : ''}"
                              >
                                Valor de {device.name}
                              </div>
                            </div>
                          {/if}
                        {/each}
                      {/if}
                      
                      <div>
                        La alerta se disparará cuando
                        {#if alertTypes[row.selectedEventIndex] && getOperators(alertTypes[row.selectedEventIndex])[row.operatorIndex]}
                          <!-- Mostrar la descripción del tipo de alerta -->
                          {alertTypes[row.selectedEventIndex].description}
                          {getOperators(alertTypes[row.selectedEventIndex])[
                            row.operatorIndex
                          ].name.toLowerCase()} a
                      
                          <!-- Agrupar y formatear los valores por dispositivo (solo dispositivos seleccionados) -->
                          {#each Object.entries(row.deviceValues
                            .filter(device => device.checked) // Solo dispositivos seleccionados
                            .reduce((groups, device) => {
                              const value = (() => {
                                if (alertTypes[row.selectedEventIndex].name === "event_code") {
                                  const event = eventCodes.find((e) => e.code === device.inputValue);
                                  return event ? event.event : device.inputValue;
                                } else if (alertTypes[row.selectedEventIndex].name === "ingnition") {
                                  const ignValue = ingnitionValues.find(
                                    (i) => i.value === parseInt(device.inputValue)
                                  );
                                  return ignValue ? ignValue.name : device.inputValue;
                                } else if (alertTypes[row.selectedEventIndex].name === "manual") {
                                  const manualValue = manualValues.find(
                                    (m) => m.value === device.inputValue
                                  );
                                  return manualValue ? manualValue.name : device.inputValue;
                                } else if (alertTypes[row.selectedEventIndex].name === "geo_type") {
                                  const geoValue = geoValues.find((g) => g.value === device.inputValue);
                                  return geoValue ? geoValue.name : device.inputValue;
                                } else if (alertTypes[row.selectedEventIndex].name === "geo_dest") {
                                  const geoDestValue = geoDestValues.find(
                                    (m) => m.value === device.inputValue
                                  );
                                  return geoDestValue ? geoDestValue.name : device.inputValue;
                                } else {
                                  return device.inputValue;
                                }
                              })();
                      
                              if (!groups[value]) {
                                groups[value] = [];
                              }
                              groups[value].push(device.name);
                              return groups;
                            }, {})) as [value, devices]}
                            
                            {#if devices.filter((d) => d !== "Por defecto").length > 0}
                              <span>
                                {value} para dispositivos {devices
                                  .filter((d) => d !== "Por defecto")
                                  .join(", ")}
                              </span>
                              {#if devices.includes("Por defecto")}
                                <span>; </span>
                              {/if}
                            {/if}
                          {/each}
                      
                          <!-- Agregar la descripción de "para todos los demás" si aplica -->
                          {#if row.deviceValues.some((device) => device.checked && device.name === "Por defecto")}
                            <span>
                              {#each row.deviceValues.filter((device) => device.checked && device.name === "Por defecto") as device}
                                {#if alertTypes[row.selectedEventIndex].name === "event_code"}
                                  <!-- Mostrar la descripción del evento -->
                                  {eventCodes.find(
                                    (e) => e.code === device.inputValue
                                  )?.event} para todos los demás
                                {:else if alertTypes[row.selectedEventIndex].name === "ingnition"}
                                  <!-- Mostrar la descripción del estado de ignición -->
                                  {ingnitionValues.find(
                                    (i) =>
                                      i.value === parseInt(device.inputValue)
                                  )?.name} para todos los demás
                                {:else if alertTypes[row.selectedEventIndex].name === "manual"}
                                  <!-- Mostrar la descripción del tipo de manual -->
                                  {manualValues.find(
                                    (m) => m.value === device.inputValue
                                  )?.name} para todos los demás
                                {:else if alertTypes[row.selectedEventIndex].name === "geo_type"}
                                  <!-- Mostrar la descripción del tipo de geocerca -->
                                  {geoValues.find(
                                    (g) => g.value === device.inputValue
                                  )?.name} para todos los demás
                                {:else if alertTypes[row.selectedEventIndex].name === "geo_dest"}
                                  <!-- Mostrar la descripción del destino de geocerca -->
                                  {geoDestValues.find(
                                    (m) => m.value === device.inputValue
                                  )?.name} para todos los demás
                                {:else}
                                  <!-- Mostrar el valor genérico -->
                                  {device.inputValue} para todos los demás
                                {/if}
                              {/each}
                            </span>
                          {/if}
                      
                          <!-- Mostrar la escala si aplica -->
                          {#if alertTypes[row.selectedEventIndex].scale}
                            <span>
                              ({alertTypes[row.selectedEventIndex].scale})
                            </span>
                          {/if}
                        {/if}
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            {/each}
          </div>
          <div class="d-flex justify-content-end mb-3">
            <button
              type="button"
              class="btn btn-secondary me-2 mt-3"
              on:click={() => addConditionRow(groupIndex)}
              disabled={!isConditionRowComplete(
                group.rows[group.rows.length - 1]
              )}>Agregar Fila de Condición</button
            >
          </div>
        </form>

        <!--{#if group.conditions.length > 0}
          <h5>Condiciones Agregadas</h5>
          <ul>
            {#each group.conditions as condition, conditionIndex}
              <li>
                {condition.event}: {condition.operator}
                {Object.entries(condition.value)
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(", ")}
                <button
                  class="btn btn-danger btn-sm"
                  on:click={() => deleteCondition(groupIndex, conditionIndex)}
                  >Eliminar</button
                >
              </li>
            {/each}
          </ul>
        {/if}-->

        <!-- Mostrar concatenación de condiciones -->
        <div class="mt-3">
          <h5>La alerta se disparará cuando:</h5>
          {#each localData.conditionGroups as group, groupIndex}
            <p>
              {#if groupIndex > 0}
                <strong>O también cuando</strong>
              {/if}
              {#each group.rows as row, rowIndex}
                {#if rowIndex > 0}
                  <strong class="ms-1"> y</strong>
                {/if}
                {getConditionDescription(row)}
              {/each}
            </p>
          {/each}
        </div>
      </div>
    </div>
  {/each}
</div>
