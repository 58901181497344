<script>
  import { onMount } from "svelte";
  import {
    alerts,
    isSimpleMode,
    downloadAlertsAsJson,
    formatUploadJson,
    getUrlParams,
    isSaveButtonDisabled,
    isEmbedded,
    isTutorialMode,
    getFinalAlertsJson,
    generateUUID,
  } from "./store.js";
  import toast from "svelte-french-toast";
  import { get } from "svelte/store";

  function switchSimpleMode() {
    isSimpleMode.set(!$isSimpleMode);
  }

  async function loadInitFile() {
    const isInitFile = getUrlParams().file;
    if (isInitFile) {
      //console.log("file to load:", isInitFile);
      //try to load the file
      try {
        const response = await fetch(`./templates/${isInitFile}.json`);
        const json = await response.json();
        if (Array.isArray(json)) {
          const formattedJson = formatUploadJson(json); // Formatear JSON

           // Cambiar los alertid si es un archivo de inicialización para evitar duplicados
          for(let alert of formattedJson){
            alert.alertid = generateUUID();
          }
          console.log("Alerts to load:", formattedJson);

          alerts.set(formattedJson);
          
          toast.success("Archivo JSON cargado con éxito.");
        } else {
          toast.error("El JSON debe contener un array de alertas.");
        }
      } catch (e) {
        toast.error("Archivo no encontrado. Por favor, verifica el archivo.");
      }
    }
  }

  onMount(async () => {
    await loadInitFile();
    // Activate tooltips
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });
  });

  let fileInput;

  function handleFileUpload(event) {
    const file = event.target.files[0];
    if (file.type !== "application/json") {
      toast.error("Solo se permiten archivos JSON.");
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      try {
        const json = JSON.parse(event.target.result);
        // Verificar que el JSON sea un array
        if (Array.isArray(json)) {
          const formattedJson = formatUploadJson(json); // Formatear JSON
          /*alerts.update((currentAlerts) => [
            ...currentAlerts,
            ...formattedJson,
          ]);*/
          alerts.set(formattedJson);
          toast.success("Archivo JSON cargado con éxito.");
        } else {
          toast.error("El JSON debe contener un array de alertas.");
        }
      } catch (e) {
        toast.error("JSON inválido. Por favor, verifica el archivo.");
      }
      fileInput.value = ""; // Reset file input
    };
    reader.readAsText(file);
  }

  function downloadJson() {
    const jsonString = JSON.stringify(getFinalAlertsJson(), null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "alert.json";
    a.click();
    URL.revokeObjectURL(url);
  }

  function returnSavedAlerts() {
    const alertsToReturn = getFinalAlertsJson();
    console.log("Data to send to parent:", alertsToReturn);

    //send alerts to parent
    console.log("Sending alerts to parent");
    console.log("Parent URL:", window.location.search);
    const destinyUrl = new URLSearchParams(window.location.search).get(
      "url_post"
    );
    window.parent.postMessage(
      { type: "alerts", body: alertsToReturn },
      destinyUrl
    );
  }

  function switchTutorialeMode() {
    isTutorialMode.set(!$isTutorialMode);
    //console.log("Tutorial mode:", $isTutorialMode);
  }
</script>

<!--<nav class="navbar navbar-dark bg-dark elevated ps-4 pe-4 mb-4">-->
<nav
  class={"navbar elevated ps-4 pe-4 " +
    ($isEmbedded
      ? " navbar-light bg-light"
      : " navbar-dark bg-dark sticky-top")}
>
  <div class="container-fluid">
    {#if $isEmbedded}
      <span class="navbar-brand mb-0 h1">Creador de Alertas</span>
    {:else}
      <a class="navbar-brand">
        <img src="solera-logo.png" alt="" style="width: 15rem" />
      </a>
    {/if}

    <form class="d-flex">
      <!--Tutorial toggle-->
      <!--<button
        class={"btn btn-outline-primary me-2 " + ($isTutorialMode ? "active" : "")}
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Cambiar a modo tutorial"
        on:click={switchTutorialeMode}
      >
        <i class="bi bi-question-circle"></i>
      </button>-->

      <!--save bttton-->
      {#if $isEmbedded}
        <button
          class="btn btn-outline-primary me-2"
          type="button"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Guardar cambios a las alertas"
          on:click={returnSavedAlerts}
        >
          Guardar <i class="bi bi-save"></i>
        </button>
      {/if}

      <button
        class="btn btn-outline-secondary me-2"
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Más información"
        on:click={() => {
          //open another tab with the documentation
          window.open(
            "https://vector.omnitracs.online/wiki/alertas/index.html",
            "_blank"
          );
        }}
      >
        <i class="bi bi-info-circle"></i>
      </button>

      <button
        class="btn btn-outline-success me-2"
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Subir plantilla"
        on:click={() => fileInput && fileInput.click()}
      >
        <i class="bi bi-file-earmark-arrow-up"></i>
      </button>
      <input
        type="file"
        accept="application/json"
        style="display: none;"
        bind:this={fileInput}
        on:change={handleFileUpload}
      />

      <button
        class="btn btn-outline-primary me-2"
        type="button"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        title="Descargar plantilla"
        on:click={downloadJson}
      >
        <i class="bi bi-file-earmark-arrow-down"></i>
      </button>
    </form>
  </div>
</nav>

<style>
  .elevated {
    box-shadow:
      0 0px 2.2px rgba(0, 0, 0, 0.034),
      0 0px 5.3px rgba(0, 0, 0, 0.048),
      0 0px 10px rgba(0, 0, 0, 0.06),
      0 0px 17.9px rgba(0, 0, 0, 0.072),
      0 0px 33.4px rgba(0, 0, 0, 0.086),
      0 0px 80px rgba(0, 0, 0, 0.12);
  }
</style>
