<script>
  import FormBody from "./FormBody.svelte";
  import Progress from "./Progress.svelte";
  import AlertPopUp from "./AlertPopUp.svelte";
  import ChoiceModal from "./ChoiceModal.svelte";
  import { writable } from "svelte/store";
  import {
    generateJson,
    fillFromJson,
    alerts,
    deleteAlert,
    devicesTypes,
    currFormStep,
    orgJson,
    isEmbedded,
    version,
    isDataModified,
  } from "./store.js";
  import NavBar from "./NavBar.svelte";
  import toast from "svelte-french-toast";

  let isOpen = writable(false);

  let showChoiceModal = writable(false);
  let lastSelectedAlertIndex = 0;

  function openPopUp() {
    currFormStep.set(0);
    isDataModified.set(false);
    isOpen.set(true);
  }

  function closePopUp() {
    isOpen.set(false);
    isDataModified.set(false);
  }

  function handleChoiceModalDeleteOpen(index) {
    showChoiceModal.set(true);
    lastSelectedAlertIndex = index;
  }

  function handleChoiceModalDelete() {
    //on:click={() => deleteAlert(index)}
    deleteAlert(lastSelectedAlertIndex);
    showChoiceModal.set(false);
    //toast con emoji de warning
    toast("Alerta eliminada con éxito!", {
      icon: "⚠️",
    });
  }

  function handlePopUpConfirm(event) {
    if (
      event.detail.detail.alert !== undefined &&
      event.detail.detail.alert !== null &&
      Object.keys(event.detail.detail.alert).length > 0
    ) {
      let returnedData = event.detail.detail.alert;
      alerts.update((currentAlerts) => [...currentAlerts, returnedData]);
      console.log("Data received from popup:", returnedData);
    }
    closePopUp();
  }

  function handleCreateAlert() {
    fillFromJson();
    openPopUp();
  }

  function handleEditAlert(alert) {
    console.log("Edit alert:", alert);
    orgJson.set(alert);
    fillFromJson(alert);
    openPopUp();
    //console.log("Edit alert:", alert);
  }

  function getUniqueDeviceTypes(alert) {
    const uniqueDeviceTypes = new Set();

    if (alert.event_alert && Array.isArray(alert.event_alert.counter)) {
      alert.event_alert.counter.forEach((group) => {
        Object.values(group).forEach((condition) => {
          if (typeof condition.val === "object") {
            Object.keys(condition.val).forEach((deviceType) => {
              if (deviceType !== "default") {
                uniqueDeviceTypes.add(deviceType);
              }
            });
          }
        });
      });
    }

    if (alert.cron_alert && Array.isArray(alert.cron_alert.counter)) {
      alert.cron_alert.counter.forEach((group) => {
        Object.values(group).forEach((condition) => {
          if (typeof condition.val === "object") {
            Object.keys(condition.val).forEach((deviceType) => {
              if (deviceType !== "default") {
                uniqueDeviceTypes.add(deviceType);
              }
            });
          }
        });
      });
    }

    return Array.from(uniqueDeviceTypes);
  }
</script>

<div class={$isEmbedded ? "" : "sticky-top"}>
  <AlertPopUp
    show={$isOpen}
    on:cancel={closePopUp}
    on:confirm={handlePopUpConfirm}
  />
</div>

<div>
  <ChoiceModal
    show={$showChoiceModal}
    title="Borrar Alerta"
    message="¿Está seguro que desea borrar la alerta?"
    on:confirm={handleChoiceModalDelete}
    on:cancel={() => showChoiceModal.set(false)}
  />
</div>

<div
  class={$isEmbedded
    ? "elevated main-embedded overflow-auto"
    : "elevated main mb-3 ms-5 me-5 mt-4"}
>
  {#if $isEmbedded}
    <div class="sticky-top" style="z-index: 0;">
      <NavBar />
    </div>
  {/if}

  <div
    class={$isEmbedded
      ? "ps-3 pe-3 pt-3"
      : "ps-3 pe-3 pt-3 pb-3 mb-3 overflow-auto"}
  >
    {#if $alerts.length > 0}
      <table class="table table-sm table-responsive table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Icono</th>
            <th scope="col">Nombre</th>
            <th scope="col">Descripción</th>
            <th scope="col">Severidad</th>
            <th scope="col">Dispositivos Específicos</th>
            <th scope="col">Estatus</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>

        <tbody>
          {#each $alerts as alert, index}
            <tr>
              <th scope="row">{index + 1}</th>
              <td>
                <span class="material-symbols-outlined">
                  {alert.icon}
                </span>
              </td>
              <td>{alert.name}</td>
              <td>{alert.description}</td>
              <td>{alert.severity}</td>
              <td>
                {#if alert}
                  {#each getUniqueDeviceTypes(alert) as deviceType}
                    <span class="badge bg-primary">{deviceType}</span>
                  {/each}
                {/if}
              </td>
              <td>
                {#if alert.disabled}
                  <span class="badge bg-danger">Desactivada</span>
                {:else}
                  <span class="badge bg-success">Activa</span>
                {/if}
              </td>

              <td>
                <button
                  class="btn btn-primary btn-sm"
                  on:click={() => handleEditAlert(alert)}
                >
                  <i class="bi bi-pencil"></i>
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  on:click={() => handleChoiceModalDeleteOpen(index)}
                >
                  <i class="bi bi-trash"></i>
                </button>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>

      <div class="d-flex justify-content-between">
        <div class="text-secondary">
          v.{version}
        </div>
        <button class="btn btn-success mb-3" on:click={handleCreateAlert}>
          <i class="bi bi-plus-circle"></i> Agregar Alerta
        </button>
      </div>
    {:else}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="d-flex justify-content-center mt-4">
        <div
          class=" alert-card mb-4 pb-4"
          style="width: 300px;"
          on:click={() => {
            openPopUp();
          }}
        >
          <div class="" style="height: 100%;">
            <div class="mt-auto mb-auto">
              <div class="d-flex justify-content-center pt-3">
                <i
                  class="bi bi-plus-circle text-success"
                  style="font-size: 6rem;"
                ></i>
              </div>
              <h5 class="card-title text-center mb-5">Agregar Alerta</h5>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .elevated {
    box-shadow:
      0 0px 2.2px rgba(0, 0, 0, 0.034),
      0 0px 5.3px rgba(0, 0, 0, 0.048),
      0 0px 10px rgba(0, 0, 0, 0.06),
      0 0px 17.9px rgba(0, 0, 0, 0.072),
      0 0px 33.4px rgba(0, 0, 0, 0.086),
      0 0px 80px rgba(0, 0, 0, 0.12);
  }

  .main {
    min-height: 85vh;
    background-color: white;
  }

  .main-embedded {
    height: 100vh;
    background-color: white;
  }

  .alert-card {
    border-radius: 50px;
    border: 1px solid #ced4da;
  }

  .alert-card:hover {
    transform: scale(1.05);
    transition: 0.3s;
  }
</style>
