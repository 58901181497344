<script>
  import { fade } from "svelte/transition";
  import { currFormStep } from "./store.js";
  import Form1 from "./Form1.svelte";
  import Form2 from "./Form2.svelte";
  import Form3 from "./Form3.svelte";
  import Form4 from "./Form4.svelte";
  import Form5 from "./Form5.svelte";
  import Form6 from "./Form6.svelte";
  import ResultJson from "./ResultJson.svelte";
  import NavFormButtons from "./NavFormButtons.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatcher = createEventDispatcher();

  let viewportComponent = null;
  const FORMS_PAGES = [Form1, Form2, Form3, Form4, Form5, Form6, ResultJson];

  function changeFormStep(step) {
    console.log(step);
    currFormStep.set(step);
  }

  function sendComfirmToParent(event) {
    let returnedData = event.detail.detail.alert;
    console.log("Data received from nav buttons:", returnedData);
    dispatcher("confirm", { detail: { alert: returnedData } });
  }

  function updateViewportComponent() {
    viewportComponent = FORMS_PAGES[$currFormStep];
  }

  updateViewportComponent();
</script>

<div style="max-height: 100%;">

  {#if viewportComponent == FORMS_PAGES[$currFormStep]}
    <div id="content-div" on:outroend={updateViewportComponent} transition:fade>
      <svelte:component this={viewportComponent} />
    </div>
  {/if}
  
  <NavFormButtons {changeFormStep} on:confirm={sendComfirmToParent} />
</div>


<style>
</style>
