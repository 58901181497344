<script>
  import toast from "svelte-french-toast";
  import {
    currFormStep,
    formTitles,
    isFormCompleted,
    generateJson,
    isEditMode,
    updateExistingAlert,
  } from "./store.js";
  import { createEventDispatcher } from "svelte";


  export let changeFormStep;
  const dispatcher = createEventDispatcher();

  function nextStep() {
    // Aquí se podría llamar a alguna función para guardar datos o hacer algo adicional antes de avanzar
    changeFormStep($currFormStep + 1);
  }

  function sendComfirmToParent() {
    const alertToReturn = generateJson();
    console.log("Data to send to form body:", alertToReturn);
    dispatcher("confirm", { detail: { alert: alertToReturn } });
  }

  function handleSave() {
    if (updateExistingAlert()) {
      toast.success("Alerta actualizada con éxito.");
      dispatcher("confirm", { detail: { alert: {} } });
    }else{
      toast.error("Error al actualizar la alerta.");
    }
  }
</script>

<div class="d-flex justify-content-end mb-3">
  
  
  <button
  type="button"
  class="btn btn-outline-primary me-2"
  on:click={() => changeFormStep($currFormStep - 1)}
  disabled={$currFormStep == 0}>Anterior</button
  >
  <!--voler invisible si es el ultimo elemento de la lista-->
  {#if $currFormStep < formTitles.length}
  <button
  type="button"
  class="btn btn-primary"
  on:click={nextStep}
  disabled={!$isFormCompleted}>Siguiente</button
  >
  {/if}
  
  <!--botón en caso de que sea el ultimo elemetno de la lista-->
  
  {#if $currFormStep == formTitles.length && !$isEditMode}
  <button
  type="button"
  class="btn btn-primary"
  on:click={() => sendComfirmToParent()}  
  disabled={!$isFormCompleted}>Finalizar</button
  >
  {/if}

  
  <!--Boton para guardar-->
  {#if $isEditMode && $currFormStep == formTitles.length}
  <button
    type="button"
    class="btn btn-outline-primary me-2"
    on:click={() => {
      handleSave();
    }}
    disabled={!$isFormCompleted}> <!--Se usa la misma variable para asegurarnos de que los datos están compeltos-->
    <i class="bi bi-save"></i> Actualizar
    </button
  >
  {/if}
</div>
