<script>
  import toast, { Toaster } from "svelte-french-toast";

  import Main from "./Main.svelte";
  import NavBar from "./NavBar.svelte";
  import {
    alerts,
    formatUploadJson,
    isEmbedded,
  } from "./store";
  import { onMount } from "svelte";

  function initApp() {
    let inIframe = false;
    try {
      inIframe = window.self !== window.top;
    } catch (e) {
      inIframe = true; // Si hay un error, probablemente estamos en un iframe
    }

    if (inIframe) {
      sendMessageToParent({ type: "command", body: "ready" });
      //isSaveButtonDisabled.set(false);
      isEmbedded.set(true);
    } else {
      //isSaveButtonDisabled.set(true);
      isEmbedded.set(false);
    }
  }
  function sendMessageToParent(message) {
    console.log("Sending message to parent:", message);
    window.parent.postMessage(message, "*");
    //irvin me envía el url padre desde url_post desde el ifrmae
  }

  function setWindowEventListeners() {
    console.log("Setting window event listeners");
    window.addEventListener("message", (event) => {
      console.log("data recieved from parent:", event.data);
      let recievedJson = event.data;
      if (recievedJson && recievedJson.type === "alerts") {
        const formattedJson = formatUploadJson(recievedJson.body); // Formatear JSON
        console.log("formattedJson:", formattedJson);
        //alerts.update((currentAlerts) => [...currentAlerts, ...formattedJson]);
        //borrar alertas e insertar las nuevas
        alerts.set(formattedJson);
        toast.success("Archivo JSON cargado con éxito.");
      }
    });
  }

  initApp();
  setWindowEventListeners();

  onMount(() => {});
</script>

<main>
  <Toaster />
  {#if !$isEmbedded}
    <NavBar></NavBar>
  {/if}

  <Main></Main>
  <!--<div class="d-flex justify-content-center mt-5">v. {version}</div>-->
</main>

<style>
</style>
