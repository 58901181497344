<script>
  import { onMount } from "svelte";
  import { generateJson } from "./store.js"; // Ajusta el camino al archivo correcto
  import toast from "svelte-french-toast";

  let jsonData = {};

  // Llama a generateJson al montar el componente
  onMount(() => {
    jsonData = generateJson();
  });
</script>

<!--<div class="d-flex justify-content-between">
    <h2>Alerta resultate</h2>
</div>-->
<!--<div class="json-viewer mb-3">
  {#if Object.keys(jsonData).length > 0}
    <code>{JSON.stringify(jsonData, null, 2)}</code>
  {:else}
    <p>No se ha generado ningún JSON aún.</p>
  {/if}
</div>-->

<div class="mb-3 mt-3" style="min-height: 500px;">
  <div class="d-flex justify-content-between">
    <h3 style="">¡Alerta creada con éxito! 🎉</h3>
      <!--Botón para copiar json el el portapapeles-->
      <button
        class="btn btn-light mb-3"
        on:click={() => {
          navigator.clipboard
            .writeText(JSON.stringify(jsonData, null, 2))
            .then(() => {
              toast.success("JSON copiado al portapapeles.");
            });
        }}>📋</button
      >
  </div>

  <div class="json-viewer mb-3">
    {#if Object.keys(jsonData).length > 0}
      <code>{JSON.stringify(jsonData, null, 2)}</code>
    {:else}
      <p>No se ha generado ningún JSON aún.</p>
    {/if}
  </div>
</div>

<style>
  .json-viewer {
    white-space: pre-wrap;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 5px;
    font-family: monospace;
    max-height: 500px;
    overflow: auto;
  }
</style>
