<script>
    import { createEventDispatcher } from 'svelte';
    
    export let show = false;
    export let message;
    export let title;
    export let type = 'confirm'; // Default type is confirm
  
    const dispatch = createEventDispatcher();
    
    function handleCancel() {
      dispatch('cancel');
    }
    
    function handleConfirm() {
      dispatch('confirm');
    }
  </script>
  
  {#if show}
    <div class="modal" style="z-index: 2;">
      <div class="modal-content">
        <div class="modal-header">
          <div>{title}</div>
        </div>
        <div class="modal-body">
          <div>{message}</div>
        </div>
        <div class="modal-footer">
          {#if type === 'confirm'}
            <button class="btn btn-outline-secondary btn-sm" on:click={handleCancel}>Cancelar</button>
          {/if}
          <button class="btn btn-outline-secondary btn-sm" on:click={handleConfirm}>Aceptar</button>
        </div>
      </div>
    </div>
  {/if}
  
  <style>
    .modal {
      position: fixed;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: rgb(0, 0, 0);
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .modal-content {
      background-color: #fefefe;
      border: 1px solid #888;
      width: 300px;
      border-radius: 5px;
      text-align: center;
    }
  
    .modal-header {
      background-color: #4c6faf;
      color: white;
      padding: 10px;
      border-radius: 5px 5px 0 0;
    }
  
    .modal-body {
      padding: 20px;
    }
  
    .modal-footer {
      display: flex;
      justify-content: space-around;
      padding: 10px;
    }
  </style>
  