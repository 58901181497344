<script>
  import { currFormStep, formTitles, generateJson, formData } from "./store.js"; // Importar función y datos necesarios

  function handleGenerateJson() {
    generateJson();
  }
</script>

<div class="d-flex justify-content-center h-100" style="border-right: 1px solid #ccc;">
  <div class="progress-container m-auto">
    {#each formTitles as step, index}
      <div class="step">
        <div class="line {index < $currFormStep ? 'active' : ''}"></div>
        <div class="circle {index <= $currFormStep ? 'active' : ''}">
          {index + 1}
        </div>
        <div>
          <h6 class="ms-3">{step.title}</h6>
          <div class="ms-3" style="margin-top: -12px; font-size:12px;">{step.description}</div>
        </div>
      </div>
    {/each}

    <div class="step">
      <div class="circle {formTitles.length <= $currFormStep ? 'active' : ''}">
        <i class="bi bi-flag-fill"></i>
      </div>
      <div>
        <h6 class="ms-3">Resultado</h6>
        <div class="ms-3" style="margin-top: -12px; font-size:12px;">Finalización</div>
      </div>
    </div>
  </div>

  <!-- Botón para generar JSON -->
  <!--<div class="mt-4">
    <button class="btn btn-primary" on:click={handleGenerateJson}>Generar JSON</button>
  </div>-->
</div>

<style>
  .progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px; /* Ajusta el ancho según tus necesidades */
    position: relative;
  }

  .step {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    margin: 20px 0;
  }

  .circle {
    height: 30px; /* Ajusta el tamaño del círculo */
    width: 30px;
    background-color: #bbb;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    z-index: 1;
    transition: background-color 0.3s;
  }

  .circle.active {
    background-color: #4CAF50; /* Color del paso activo */
  }

  .line {
    position: absolute;
    top: 25px; /* Ajusta la posición superior */
    left: 13px; /* Ajusta la posición izquierda */
    width: 4px;
    height: 5rem; /* Ajusta la altura */
    background-color: #bbb;
    z-index: 0;
    transition: background-color 0.3s;
  }

  .line.active {
    background-color: #4CAF50; /* Color de la línea activa */
  }
</style>
